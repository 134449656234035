<template>
  <template v-if="isCurrentStep">
    <form-group
      rules="required"
      name="existing_user"
      type="radio"
      :label="t('ttmt.invitations.texts.employee_already_have_account')"
      :form-control-props="{
        options: yesNoOptions,
      }"
    />
  </template>

  <template v-else>
    <form-group
      type="hidden"
      name="existing_user"
    />
  </template>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useSelectOptions from '@shared/hooks/form/selectOptions'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // Step object which contains the following struct:
  //   step: 0,                       // step counter
  //   name: 'Name',                  // step name
  //   component: StepDocuments,      // component to display
  //   props: {},                     // component's props
  //   emits: {},                     // component's events
  //   icon: {                        // icon to display in form wizard nav
  //     library: 'coolicon',         // icon's library used
  //     name: 'file_blank_outline',  // icon's name
  //     iconStyle: null,             // icon's style
  //   },
  step: {
    type: Object,
    default: () => ({}),
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
})

const { t } = useI18n()
const { yesNoOptions } = useSelectOptions()

// ---------- CURRENT STEP ----------

const isCurrentStep = computed(() => (
  props.step.step === props.currentStep
))
</script>
