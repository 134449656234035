import { useI18n } from 'vue-i18n'

import useBreadcrumb from '@shared/hooks/breadcrumbs'
import useInstitutionEmployee from '@extranet/hooks/institutionEmployee'

export default function breadcrumbInstitutionEmployee() {
  const { t } = useI18n()
  const { forceLink } = useBreadcrumb()
  const { getLabel } = useInstitutionEmployee()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function index() {
    return [
      {
        text: t('ttmt.breadcrumbs.institutions_employees.institutions_employees'),
        link: false,
        to: '/institutions-employees',
      },
    ]
  }

  /**
   * Breadcrumb for invite page
   *
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function invite() {
    let breadcrumbs = index()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: getLabel(),
        link: false,
        to: '/institutions-employees/invite',
      },
    )

    return breadcrumbs
  }

  return {
    index,
    invite,
  }
}
