<template>
  <app-tutorial-button
    :tutorials="tutorials"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppTutorialButton from '@extranet/components/ui/help/AppTutorialButton.vue'
import TutorialInstitutionEmployeeFunctioning from '@extranet/components/tutorials/institution_employee/TutorialInstitutionEmployeeFunctioning.vue'
import TutorialInstitutionEmployeeInvite from '@extranet/components/tutorials/institution_employee/TutorialInstitutionEmployeeInvite.vue'
import TutorialInstitutionEmployeeApprove from '@extranet/components/tutorials/institution_employee/TutorialInstitutionEmployeeApprove.vue'
import TutorialInstitutionEmployeeDecline from '@extranet/components/tutorials/institution_employee/TutorialInstitutionEmployeeDecline.vue'
import TutorialInstitutionEmployeeRemove from '@extranet/components/tutorials/institution_employee/TutorialInstitutionEmployeeRemove.vue'
import TutorialInstitutionEmployeeCancel from '@extranet/components/tutorials/institution_employee/TutorialInstitutionEmployeeCancel.vue'

const { t } = useI18n()

// ---------- TUTORIALS ----------

const tutorials = computed(() => ([
  {
    menuItem: t('ttmt.tutorials.extranet.institution_employee.functioning.main_title'),
    component: TutorialInstitutionEmployeeFunctioning,
  },
  {
    menuItem: t('ttmt.tutorials.extranet.institution_employee.invite.main_title'),
    component: TutorialInstitutionEmployeeInvite,
  },
  {
    menuItem: t('ttmt.tutorials.extranet.institution_employee.approve.main_title'),
    component: TutorialInstitutionEmployeeApprove,
  },
  {
    menuItem: t('ttmt.tutorials.extranet.institution_employee.decline.main_title'),
    component: TutorialInstitutionEmployeeDecline,
  },
  {
    menuItem: t('ttmt.tutorials.extranet.institution_employee.remove.main_title'),
    component: TutorialInstitutionEmployeeRemove,
  },
  {
    menuItem: t('ttmt.tutorials.extranet.institution_employee.cancel.main_title'),
    component: TutorialInstitutionEmployeeCancel,
  },
]))
</script>
