<template>
  <p class="font-bold text-xl mb-4">
    {{ t('ttmt.tutorials.extranet.institution_employee.functioning.title') }}
  </p>

  <p>
    {{ t('ttmt.tutorials.extranet.institution_employee.functioning.content.section') }}
  </p>

  <p class="mt-4">
    {{ t('ttmt.tutorials.extranet.institution_employee.functioning.content.invite') }}
  </p>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
